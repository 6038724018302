import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AssignedPlan from "./components/AssignedPlan";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";

import SaveCancel from "../../../components/SaveCancel";
import useUser from "../../../hooks/useUser";
import UserFields from "./components/UserFields";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import PlanDetails from "./components/PlanDetails";
import BranchAndOthers from "./components/BranchAndOthers";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

import AddressDetail from "../../../components/AddressDetail";
import InstallationAddressDetail from "../../../components/InstallationAddressDetail";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const useTabStyles = styled({
  root: {
    justifyContent: "center",
    width: "1000px",
  },
  scroller: {
    flexGrow: "0",
  },
});

const Alert = styled(MuiAlert)(spacing);

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const validationSchema = Yup.object().shape({
  tenant: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  branch: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),

  mobileNo: Yup.string()
    .required("Required")
    .matches(/^\d{10}$/, "Must be a valid phone number with up to 10 digits"),
  emailId: Yup.string("Enter email id")
    .required("Email id is required")
    .email("Enter a valid email"),

  fullName: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  installationAddress: Yup.string().required("Required"),
  userServiceType: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  connectionType: Yup.string().required("Required"),
});

function EditUser() {
  const dispatch = useDispatch();
  const { getUser, user, updateUser, isSubmitting } = useUser();
  const classes = useTabStyles();

  React.useEffect(() => {
    if (Object.keys(user).length !== 0) {
      console.log(user.installationAddress, "user.installationAddress");
      const initialValues = {
        radUserId: user.radUserId,
        mobileNo: user.mobileNo,
        emailId: user.emailId,
        fullName: user.fullName,
        gstNo: user.gstNo,
        address: user.address,
        installationAddress: user.installationAddress,
        tenant: {
          value: user.tenantId,
          label: user.tenantName,
        },
        branch: {
          value: user.branchId,
          label: user.branchName,
        },
        userServiceType: user.userServiceType == "IN" ? "INTERNET" : "COMBO",
        connectionType: user.connectionType == "HU" ? "HOME_USER" : "BUSINESS_USER",
        status: user.status,
      };
      reset(initialValues);
    }
  }, [user]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { watch } = methods;

  const presentAddressFields = watch(["address"]);

  const { state } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      data["branch"] = {
        branchId: data.branch.value,
        branchName: data.branch.label,
      };
      data["tenant"] = {
        tenantId: data.tenant.value,
        tenantName: data.tenant.label,
      };
      await updateUser(data);
      cancelBtn();
      dispatch(showSuccessSnackbar("User updated successfully!"));
    } catch (err) {
      let messageDetails = [];
      if (err.errors != undefined) {
        let errors = err.errors;
        for (let error in errors) {
          messageDetails.push(errors[error].field + " " + errors[error].message);
        }
        dispatch(showErrorSnackbar(messageDetails.join(",")));
      } else {
        dispatch(showErrorSnackbar(err.message));
      }
    }
  };

  const cancelBtn = () => {
    navigate("/user/userlist/list");
  };

  const [value, setValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>User</Typography>
        <Typography>UserList</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getUser(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_user")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    classes={{ scroller: classes.scroller }}
                    value={value}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="User"
                    sx={{
                      justifyContent: "center",
                      width: {
                        xs: "100%", // Full width on mobile devices
                        sm: "500px", // Fixed width on small devices
                        md: "700px", // Wider on medium devices
                        lg: "900px", // Even wider on larger devices
                      },
                      minWidth: "300px", // Ensure a minimum width across all devices
                      maxWidth: "100%", // Ensure it doesn't overflow the container
                    }}
                  >
                    <Tab label="User Details" {...a11yProps(0)} />
                    <Tab label="Address " {...a11yProps(1)} />

                    <Tab label="Branch and Others" {...a11yProps(2)} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <UserFields
                      validationFields={validationSchema.fields}
                      hideFields={[
                        "userName",
                        "routerPassword",
                        "confirmRouterPassword",
                        "appPassword",
                        "confirmAppPassword",
                      ]}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <AddressDetail
                      validationFields={validationSchema.fields}
                      presentAddressFields={presentAddressFields}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <BranchAndOthers validationFields={validationSchema.fields} />
                  </TabPanel>
                </Box>
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default EditUser;
