import React from "react";
import styled from "@emotion/styled";
import { Chart } from "react-chartjs-2";
import { useTheme } from "@mui/material/styles";
import { MoreVertical } from "react-feather";

import { green, red, orange } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  Chip,
  Box,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";
import useScheduleUser from "../../../hooks/useScheduleUser";
import { formatBytes, convertMBToBytes } from "../../../utils/common";

const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const BasicDetails = ({ user }) => {
  const { activeScheduleUser } = useScheduleUser();
  const theme = useTheme();
  const CardContent = styled(MuiCardContent)`
    &:last-child {
      padding-bottom: ${theme.spacing(2)};
    }
  `;
  console.log(user, "user");
  const data = {
    labels: ["Social", "Search Engines", "Direct", "Other"],
    datasets: [
      {
        data: [260, 125, 164],
        backgroundColor: [theme.palette.secondary.main, red[500], orange[500]],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const userStatus = (status) => {
    if (status == "active") {
      return <Chip label="Active" color="primary" />;
    } else if (status == "online") {
      return <Chip label="Online" color="success" />;
    } else if (status == "expired") {
      return <Chip label="Expired" color="warning" />;
    }
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <>
      <Box>
        <Card mb={6} sx={{ backgroundColor: "sss" }}>
          <CardHeader
            action={
              <IconButton aria-label="settings" size="large">
                <MoreVertical />
              </IconButton>
            }
            title="User Details"
          />

          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  UserName:
                </TableCell>
                <TableCell align="right">{user.userName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  User FullName:
                </TableCell>
                <TableCell align="right">{user.fullName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Expiration:
                </TableCell>
                <TableCell align="right">{user.expiration}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Tenant Id:
                </TableCell>
                <TableCell align="right">{user.tenantId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Branch Name:
                </TableCell>
                <TableCell align="right">{user.branchName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Connection Type:
                </TableCell>
                <TableCell align="right">{user.connectionType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Account No:
                </TableCell>
                <TableCell align="right">{user.radUserId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Created Date:
                </TableCell>
                <TableCell align="right">{user.createdAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Registered Date:
                </TableCell>
                <TableCell align="right">{user.registeredDate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Created By:
                </TableCell>
                <TableCell align="right">{user.createdBy}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CAF No:
                </TableCell>
                <TableCell align="right">{user.cafNo}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Account Type:
                </TableCell>
                <TableCell align="right">{user.accountType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Mobile No:
                </TableCell>
                <TableCell align="right">{user.mobileNo}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Email Id:
                </TableCell>
                <TableCell align="right">{user.emailId}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  User Status:
                </TableCell>
                <TableCell align="right">{userStatus(user.userStatus)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Scheduled Date:
                </TableCell>
                <TableCell align="right">{activeScheduleUser.scheduleDateStr}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Gst No:
                </TableCell>
                <TableCell align="right">{user.gstNo}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Box>
    </>
  );
};

export default BasicDetails;
