import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  InputAdornment,
  Box,
  Typography,
} from "@mui/material";
import { Search as SearchIcon, Clear as ClearIcon } from "@mui/icons-material";
import Highlight from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import _debounce from "lodash/debounce";
import useUser from "../../hooks/useUser";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const NavbarSearch = () => {
  const { globalAutoSearchUser, globalAutoSearchResults, isLoading } = useUser();
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [highlightWords, setHighlightWords] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const navigate = useNavigate();
  const [searchPayload, setPayload] = useState(currentPayload);

  // Debounced search function to prevent excessive API calls
  const handleSearch = async (query) => {
    console.log("helllo", query);
    if (query) {
      const payloadValues = {
        ...searchPayload,
        filters: [
          {
            fieldName: "userName",
            fieldValue: query,
            op: "like",
            dataType: "string",
            condition: "and",
            isJoin: false,
          },
        ],
      };
      const results = await globalAutoSearchUser(payloadValues);
      console.log("Search results:", results);
      setHighlightWords([query]);
    } else {
      setSearchResults([]);
      setHighlightWords([]);
    }
  };

  const debouncedSearch = _debounce(handleSearch, 300);

  useEffect(() => {
    setSearchResults(globalAutoSearchResults);
  }, [globalAutoSearchResults]);

  // Handle input changes
  const handleInputChange = (event, newInputValue) => {
    if (event.type == "change") {
      setSearchValue(newInputValue);
      debouncedSearch(newInputValue);
    }
  };

  // Handle selection from autocomplete dropdown
  const handleOptionSelect = (event, option) => {
    console.log(option, "optionoptionoption");
    if (typeof option === "object") {
      setSearchValue("");
      setSearchResults([]);
      setSelectedValue(null);
      navigate(`/user/details?id=${option.radUserId}`);
    }
  };
  const handleClear = () => {
    setSearchValue("");
    setSearchResults([]);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#e0f7fa", // Light cyan shade
        color: "#006064", // Dark cyan for contrast
      }}
    >
      <Autocomplete
        freeSolo
        options={searchResults}
        getOptionLabel={(option) =>
          option.userName +
          " - " +
          option.fullName +
          " - " +
          option.mobileNo +
          " - " +
          option.emailId
        }
        inputValue={searchValue}
        value={selectedValue}
        onInputChange={handleInputChange}
        onChange={handleOptionSelect}
        loading={isLoading}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            key={option.radUserId}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1, // Add some padding for spacing
              backgroundColor: "#e0f7fa", // Light cyan shade
              color: "#006064", // Dark cyan for contrast
            }}
          >
            <Typography
              variant="body1"
              sx={{
                flexBasis: "20%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Highlight
                searchWords={highlightWords}
                autoEscape={true}
                textToHighlight={option.userName}
              />
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                flexBasis: "20%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Highlight
                searchWords={highlightWords}
                autoEscape={true}
                textToHighlight={option.fullName}
              />
            </Typography>

            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                flexBasis: "25%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Highlight
                searchWords={highlightWords}
                autoEscape={true}
                textToHighlight={option.mobileNo}
              />
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                flexBasis: "40%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Highlight
                searchWords={highlightWords}
                autoEscape={true}
                textToHighlight={option.emailId}
              />
            </Typography>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search Users"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {searchValue && (
                    <InputAdornment position="end" onClick={handleClear} sx={{ cursor: "pointer" }}>
                      <ClearIcon />
                    </InputAdornment>
                  )}
                </>
              ),
            }}
            fullWidth
          />
        )}
      />
    </Box>
  );
};

export default NavbarSearch;
