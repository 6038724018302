import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import useTenant from "../../../../hooks/useTenant";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useAuth from "../../../../hooks/useAuth";

const currentTenantPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function UserFields(props) {
  const { user } = useAuth();
  console.log(user, "useruseruser");
  const { autoSearchTenant, tenantAutoSearchResults } = useTenant();
  const { validationFields, hideFields } = props;
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const [tenantPayload, setTenantPayload] = useState(currentTenantPayload);
  const [tenantOptions, setTenantOptions] = useState([]);

  const fetchTenants = async () => {
    await autoSearchTenant(tenantPayload);
  };

  useEffect(() => {
    console.log(user, "user.businessType");
    if (
      user &&
      (user.businessType == "SUPER_ADMIN" ||
        user.businessType == "DISTRIBUTOR" ||
        user.businessType == "MULTI_SERVICE_OPERATOR")
    ) {
      fetchTenants();
    }
  }, [user, tenantPayload]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (tenantAutoSearchResults.data.length !== 0) {
      const results = [...tenantOptions, ...tenantAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setTenantOptions(filteredResults);
    }
  }, [tenantAutoSearchResults]);

  return (
    <Grid container spacing={12} className="row" pt={8}>
      {user &&
        (user.businessType === "SUPER_ADMIN" ||
          user.businessType == "DISTRIBUTOR" ||
          user.businessType == "MULTI_SERVICE_OPERATOR") && (
          <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
            <SearchableAutoComplete
              control={control}
              data={tenantOptions}
              label={t("select_franchise")}
              payload={tenantPayload}
              setPayload={setTenantPayload}
              loading={false}
              name="tenant"
              multiple={false}
              defaultValue={""}
              hasNextPage={false}
              fields={validationFields}
            />
          </Grid>
        )}
      {hideFields && !hideFields.includes("userName") && (
        <Grid item md={6}>
          <CustomTextField
            label={t("user_name")}
            name="userName"
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            fields={validationFields}
          />
        </Grid>
      )}
      {hideFields && !hideFields.includes("routerPassword") && (
        <Grid item md={6}>
          <CustomTextField
            label={t("router_password")}
            name="routerPassword"
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            fields={validationFields}
          />
        </Grid>
      )}
      {hideFields && !hideFields.includes("confirmRouterPassword") && (
        <Grid item md={6}>
          <CustomTextField
            label={t("confirm_router_password")}
            name="confirmRouterPassword"
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            fields={validationFields}
          />
        </Grid>
      )}
      {hideFields && !hideFields.includes("appPassword") && (
        <Grid item md={6}>
          <CustomTextField
            label={t("app_password")}
            name="appPassword"
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            fields={validationFields}
          />
        </Grid>
      )}
      {hideFields && !hideFields.includes("confirmAppPassword") && (
        <Grid item md={6}>
          <CustomTextField
            label={t("confirm_app_password")}
            name="confirmAppPassword"
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            fields={validationFields}
          />
        </Grid>
      )}
      <Grid item md={6}>
        <CustomTextField
          label={t("Full Name")}
          name="fullName"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("Mobile Number")}
          name="mobileNo"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("Email Id")}
          name="emailId"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>

      <Grid item md={6}>
        <CustomTextField
          label={t("GST No")}
          name="gstNo"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          name="userServiceType"
          label={t("user_service_type")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"INTERNET"}>Internet</MenuItem>
          <MenuItem value={"COMBO"}>Combo</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          name="connectionType"
          label={t("connection_type")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"HOME_USER"}>Home User</MenuItem>
          <MenuItem value={"BUSINESS_USER"}>Business User</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          name="status"
          label={t("Status")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
          <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
}

export default UserFields;
