import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Breadcrumbs,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import InvoiceLogo from "../vendor/invoice_logo.png";
import InvoiceLogoScvn from "../vendor/invoice_logo_scvn.jpg";
import InvoiceLogoST from "../vendor/st_logo.png";
import useUser from "../hooks/useUser";

const StyledCard = styled(Card)`
  margin: 20px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

// CSS to hide the button during print
const PrintButtonContainer = styled("div")`
  @media print {
    display: none;
  }
`;

const InvoiceDetails = () => {
  const [logoPath, setLogoPath] = useState("../../vendor/invoice_logo.png");
  const componentRef = useRef();

  console.log(componentRef, "componentRef");
  const { state } = useLocation();
  const { getRenewal, renewal } = useUser();

  const fetchData = async () => {
    await getRenewal(state.id);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
    return Promise.resolve();
  }, []);

  const showLogo = (tenantName) => {
    if (tenantName == "SVCN Communication") {
      return InvoiceLogoScvn;
    } else if (tenantName == "Success Telecom") {
      return InvoiceLogoST;
    } else {
      return InvoiceLogo;
    }
  };

  const printFn = useReactToPrint({
    contentRef: componentRef,
    documentTitle: `Invoice_${renewal?.invoiceNo || ""}`,
    onAfterPrint: handleAfterPrint,
    onBeforePrint: handleBeforePrint,
  });

  const printInvoice = () => {
    const content = document.getElementById("invoice_details").innerHTML;
    const printWindow = window.open("", "_blank");

    printWindow.document.write(`
        <html>
            <head>
                <title>Print Invoice</title>
                <style>
                    ${Array.from(document.styleSheets)
                      .map((styleSheet) => {
                        try {
                          return styleSheet.ownerNode.outerHTML;
                        } catch (err) {
                          console.warn("Could not access stylesheet", err);
                          return "";
                        }
                      })
                      .join("")}
                </style>
            </head>
            <body>
                <div id="invoice_details">${content}</div>
                <script>
                    window.onload = function() {
                        window.print();
                        window.close();
                    };
                </script>
            </body>
        </html>
    `);
    printWindow.document.close();
  };

  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Invoice
      </Typography>
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
        <Typography color="text.primary">Home</Typography>
        <Typography color="text.primary">Invoices</Typography>
        <Typography color="text.primary">Invoice Details</Typography>
      </Breadcrumbs>
      <Grid container id="invoice_details">
        <Grid item xs={12} md={12}>
          <StyledCard ref={componentRef}>
            <CardContent>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={6}>
                  <img src={showLogo(renewal.msoName)} alt="Invoice Logo" width={150} />
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Typography variant="h6">{renewal.msoName}</Typography>
                  {renewal.poweredBy != null && (
                    <Typography>{"(" + renewal.poweredBy + ")"}</Typography>
                  )}
                  <Typography>{renewal.msoAddress1}</Typography>
                  <Typography>{renewal.msoAddress2}</Typography>
                  <Typography>{renewal.msoAddress3}</Typography>
                  <Typography>Gst No: {renewal.msoGst}</Typography>
                  <Typography>Email: {renewal.msoEmail}</Typography>
                </Grid>
              </Grid>
              <Divider sx={{ margin: "20px 0" }} />
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={3} textAlign="left">
                  <Typography variant="h6">Bill To:</Typography>
                  <Typography>Name: {renewal.fullName}</Typography>
                  <Typography style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                    Address: {renewal.newAddress}
                  </Typography>
                  <Typography>Mobile No: {renewal.mobileNo}</Typography>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={6}>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>UserName:</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="left">{renewal.userName}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Customer GST:</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="left">{renewal?.gstNo}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Invoice No:</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="left">{renewal?.invoiceNo}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Invoice Date:</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="left">{renewal?.renewDate}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Paid Date:</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="left">{renewal?.paidDate}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Billing Period:</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="left">{renewal?.billingPeriod}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider sx={{ margin: "20px 0" }} />
              <Grid container spacing={2} justifyContent="space-between"></Grid>

              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{renewal.planName}</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell align="right">{"₹" + renewal.planAmount}</TableCell>
                  </TableRow>
                  {renewal.sgstValue !== 0 && (
                    <TableRow>
                      <TableCell>
                        {"SGST @ " + renewal.sgstValue + "% on Rs. " + renewal.planAmount}
                      </TableCell>
                      <TableCell>1</TableCell>
                      <TableCell align="right">{"₹" + renewal.sgstAmount}</TableCell>
                    </TableRow>
                  )}
                  {renewal.cgstValue !== 0 && (
                    <TableRow>
                      <TableCell>
                        {"CGST @ " + renewal.cgstValue + "% on Rs. " + renewal.planAmount}
                      </TableCell>
                      <TableCell>1</TableCell>
                      <TableCell align="right">{"₹" + renewal.cgstAmount}</TableCell>
                    </TableRow>
                  )}
                  {renewal.igstValue !== 0 && (
                    <TableRow>
                      <TableCell>
                        {"IGST @ " + renewal.igstValue + "% on Rs. " + renewal.planAmount}
                      </TableCell>
                      <TableCell>1</TableCell>
                      <TableCell align="right">{"₹" + renewal.igstAmount}</TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      <strong>Subtotal</strong>
                    </TableCell>
                    <TableCell align="right">{"₹" + renewal.finalInvoiceAmount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      <strong>Total</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>{"₹" + renewal.finalInvoiceAmount}</strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Divider sx={{ margin: "20px 0" }} />
              <Typography align="center">
                ***This is a computer-generated receipt, no signature required***
              </Typography>
              {/* Print Button using react-to-print */}
              <Grid container justifyContent="flex-end" sx={{ marginTop: 2 }}>
                <PrintButtonContainer>
                  <Button variant="contained" color="primary" onClick={printFn}>
                    Print Invoice
                  </Button>
                </PrintButtonContainer>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default InvoiceDetails;
