import React from "react";
import styled from "@emotion/styled";
import { Chart } from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import { useTheme } from "@mui/material/styles";

import { green, red, orange } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Box,
  Grid,
  Chip,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { spacing } from "@mui/system";
import useScheduleUser from "../../../hooks/useScheduleUser";
import { formatBytes, convertMBToBytes } from "../../../utils/common";
import usePlan from "../../../hooks/usePlan";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)};
  }
`;

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const OtherDetails = ({ user }) => {
  const theme = useTheme();
  const CardContent = styled(MuiCardContent)`
    &:last-child {
      padding-bottom: ${theme.spacing(2)};
    }
  `;
  const { getPlan, plan, updatePlan, isSubmitting } = usePlan();
  const data = {
    labels: ["Social", "Search Engines", "Direct", "Other"],
    datasets: [
      {
        data: [260, 125, 164],
        backgroundColor: [theme.palette.secondary.main, red[500], orange[500]],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const { activeScheduleUser } = useScheduleUser();

  const userStatus = (status) => {
    if (status == "active") {
      return <Chip label="Active" color="primary" />;
    } else if (status == "online") {
      return <Chip label="Online" color="success" />;
    } else if (status == "expired") {
      return <Chip label="Expired" color="warning" />;
    }
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <>
      <Box>
        <Card sx={{ backgroundColor: "sss" }}>
          <CardHeader
            action={
              <IconButton aria-label="settings" size="large">
                <MoreVertical />
              </IconButton>
            }
            title="Other Details"
          />

          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Plan Name:
                </TableCell>
                <TableCell align="right">{user.planName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Validatity:
                </TableCell>
                <TableCell align="right">{user.validity}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Rate:
                </TableCell>
                <TableCell align="right">{user.rateName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Monthly Total:
                </TableCell>
                <TableCell align="right">{formatBytes(user.quotaLimit)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Used Data:
                </TableCell>
                <TableCell align="right">{formatBytes(user.usedQuotaLimit)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Balance:
                </TableCell>
                <TableCell align="right">{formatBytes(user.balanceQuotaLimit)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Start Time:
                </TableCell>
                <TableCell align="right">{user.acctStartDate}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Last Login Type:
                </TableCell>
                <TableCell align="right">{user.lastLoginType}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Running Plan:
                </TableCell>
                <TableCell align="right">{user.runningPlan}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Current MAC:
                </TableCell>
                <TableCell align="right">{user.currentMac}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Login NAS IP:
                </TableCell>
                <TableCell align="right">{user.nasIpAddress}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Last Renewal Date:
                </TableCell>
                <TableCell align="right">{user.lastRenewalDate}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Auto Renewal Enabled:
                </TableCell>
                <TableCell align="right">
                  {user.autoRenewalEnabled ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CancelIcon color="disabled" />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Box>
    </>
  );
};

export default OtherDetails;
