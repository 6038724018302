import React, { useEffect, useState, Suspense } from "react";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowUpward, MoreVert, TrendingUp } from "@mui/icons-material";

import {
  Grid,
  IconButton,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";

import { spacing } from "@mui/system";
import { green, red } from "@mui/material/colors";
import { a11yProps } from "../../../utils/common";
import Actions from "./Actions";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import DoughnutChart from "./DoughnutChart";
import Stats from "./Stats";
import Table from "./Table";
import useDashboard from "../../../hooks/useDashboard";
import useAuth from "../../../hooks/useAuth";
import useTenant from "../../../hooks/useTenant";
import DashboardDataGridWidget from "./DashboardDataGridWidget";
import useDailyUser from "../../../hooks/useDailyUser";

import {
  ButtonBase,
  Button,
  Tabs,
  CardHeader,
  CardMedia,
  Tab,
  Box,
  Paper,
  Avatar,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import TabPanel from "../../../components/TabPanel";
import Today from "./user_expiring/today";
import Yesterday from "./user_expiring/yesterday";
import Tomorrow from "./user_expiring/tomorrow";
import Next from "./user_expiring/next";
import useMainContentDimensions from "../../../utils/mainContentDimensions";
import DashboardTable from "./Table";
import MyBarChart from "./DashboardChart";
import BarCharts from "./DashboardChart";
import AED from "./AgreeExpDet";
import BalanceSheet from "./BalanceSheet";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const useTabStyles = styled({
  root: {
    justifyContent: "center",
    width: "1000px",
  },
  scroller: {
    flexGrow: "0",
  },
});

function Default(props) {
  console.log(props, "dashboard props");
  const [DynamicComponent, setDynamicComponent] = useState(null);
  const classes = useTabStyles();
  const { user } = useAuth();
  const { getTenant, tenant } = useTenant();
  const { getAllDashboardUser, dashboardUsers } = useDailyUser();
  const { t } = useTranslation();

  const { width: mainContentWidth } = useMainContentDimensions();

  const [payload, setPayload] = useState(currentPayload);

  const { getDashboardUserCount, dashboadUserCount, isUserDashboardLoading } = useDashboard();

  const fetchDashboardData = async () => {
    await getDashboardUserCount(payload);
  };

  const fetchTenantData = async () => {
    await getTenant(user.tenantId);
  };

  console.log(dashboardUsers, "onloiiinne");

  const [fullName, setFullName] = useState("");

  const [walletBalance, setWalletBalance] = useState(0.0);

  const greetMessage = () => {
    const myDate = new Date();
    const hrs = myDate.getHours();

    let greet;

    if (hrs < 12) greet = "Good Morning";
    else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
    else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";
    return greet;
  };

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const loadComponent = async (componentName) => {
    try {
      const { default: MyComponent } = await import("./" + componentName);
      setDynamicComponent(() => MyComponent);
    } catch (error) {
      console.error("Error loading the component:", error);
    }
  };

  useEffect(() => {
    if (user) {
      setFullName(user.name);
      fetchDashboardData();
    }
    if (user && user.businessType != "SUPER_ADMIN" && user.businessType != "USER") {
      fetchTenantData();
    }
    console.log(user, "user.businessType");
    if (user && user.businessType == "SUPER_ADMIN") {
      loadComponent("SuperAdminDashboard");
    } else if (user && user.businessType == "MULTI_SERVICE_OPERATOR") {
      loadComponent("MsoDashboard");
    } else if (user && user.businessType == "DISTRIBUTOR") {
      loadComponent("DisDashboard");
    } else if (user && user.businessType == "LOCAL_CABLE_OPERATOR") {
      loadComponent("LcoDashboard");
    } else if (user && user.businessType == "BULK_BANDWIDTH") {
      loadComponent("BBWDashboard");
    } else if (user && user.businessType == "HOTEL") {
      loadComponent("HotelDashboard");
    } else if (user && user.businessType == "USER") {
      loadComponent("UserDashboard");
    }
  }, [user]);

  useEffect(() => {
    console.log(tenant, "tenanttenant");
    if (Object.keys(tenant).length !== 0) {
      setWalletBalance(tenant.walletBalance);
    }
  }, [tenant]);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        {DynamicComponent ? <DynamicComponent /> : null}
      </Suspense>
    </>
  );
}

export default Default;
